export const PRIVACY_POLICY = "https://renthub.kberen.com/policy";
export const API_KEY = "AIzaSyCe1jzmkXqby8imh6TxImACo2nDGulrZ84";
export const ADDRESS = {
  email: "renthub@kberen.com",
  phone1: "+254-725-761794",
  phone2: "+1 (202) 880-9206",
  location: "Moi uni (main), Eldoret, Kenya",
};
export const CALENDLY_URL = "https://calendly.com/renthub-demo/30min";
export const PLAYSTORE_URL = "https://play.google.com/store/apps/details?id=com.kberen.renthub&hl=en";
export const LAST_POLICY_UPDATE = "15th August, 2024";
export const RENTHUB_WEB = window.location.origin;
