import { useState } from "react";
import "../index.css";

interface TestimonialsCardProps {
  image: string;
  name: string;
  propertyName: string;
  description: string;
  className?: string;
  onReadMore: () => void;
}

export function TestimonialsCard({ image, name, propertyName, description, className = "", onReadMore }: TestimonialsCardProps) {
  // Function to truncate the description
  const truncateDescription = (desc: string, length: number) => {
    if (desc.length <= length) return desc;
    return desc.substring(0, length) + "...";
  };

  // Set the maximum length for truncated descriptions
  const truncatedDescription = truncateDescription(description, 100);

  return (
    <div
      className={`rounded-xl bg-testimonialCard md:shadow-custom flex flex-col md:flex-row justify-between gap-2 w-[80%] md:max-lg:w-[80%] md:justify-between md:max-lg:gap-8 h-120 md:h-auto p-4 2xl:max-w-screen-lg ${className}`}
    >
      <div className="flex flex-col items-center md:items-start  md:w-1/4">
        <div className="w-24 h-24 md:w-40 md:h-40 mb-2 md:rounded-lg rounded-xl overflow-hidden">
          <img src={image} alt={name} className="w-full h-full object-cover" />
        </div>
        <div className="md:p-4">
          <h3 className="text-base md:text-sm md:text-lg font-bold text-white text-start">{name}</h3>
          <h4 className="text-xs md:text-sm font-bold text-white text-start">{propertyName}</h4>
        </div>
      </div>
      <div className="w-full md:w-3/4 flex flex-col justify-between">
        <p className="text-sm md:text-lg font-bold mt-2 block md:hidden font-glory">{truncatedDescription}</p>
        <p className="text-sm md:text-lg 2xl:text-2xl font-bold mt-2 hidden md:block font-glory">{description}</p>
        <button onClick={onReadMore} className="text-blue-white hover:underline mt-2 block md:hidden">
          Read more
        </button>
      </div>
    </div>
  );
}
