import { TestimonialsCard } from "../components";
import { Carousel } from "flowbite-react";
import { useState } from "react";
import { VscChromeClose } from "react-icons/vsc";

interface Testimonial {
  image: string;
  name: string;
  propertyName: string;
  description: string;
}

interface TestimonialInterface {
  testimonials: Testimonial[];
}

export function Testimonials({ testimonials }: TestimonialInterface) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [modalContent, setModalContent] = useState("");

  const handleReadMore = (testimonial: Testimonial) => {
    setModalContent(testimonial.description);
    setName(testimonial.name);
    setIsModalOpen(true);
  };

  return (
    <div id="testimonials" className="py-8 px-4 bg-blue-300  ">
      <div>
        <h2 className="text-xl md:text-3xl text-center font-bold text-blue-900 md: mb-10 lg:mb-4">Hear from our users</h2>
        <p className="text-lg md:text-xl font-normal text-center max-w-2xl mx-auto lg:mb-8">
          Don't just take our word for it—see what our users have to say about their seamless and efficient experience with RentHub.
        </p>
      </div>
      <div className="md:h-80 sm:h-80 xl:h-80 2xl:h-96 md:mt-5">
        <Carousel className="relative overflow-hidden md:h-80 p-0" pauseOnHover>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="flex items-center justify-center  m-0 ">
              <TestimonialsCard
                image={testimonial.image}
                name={testimonial.name}
                propertyName={testimonial.propertyName}
                description={testimonial.description}
                className="bg-testimonialCard flex-shrink-0 w-[75%] md:w-[60%] h-full"
                onReadMore={() => handleReadMore(testimonial)}
              />
            </div>
          ))}
        </Carousel>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-70"></div>
          <div className="bg-white border border-custom-stroke backdrop-blur-custom p-4 rounded-lg shadow-lg z-50 w-3/4 md:w-1/2 lg:w-1/3">
            <button onClick={() => setIsModalOpen(false)} className="float-right text-xl font-bold">
              <VscChromeClose />
            </button>
            <h2 className="text-xl font-semibold mb-4 text-centre"> {name}'s Testimonial</h2>
            <p>{modalContent}</p>
          </div>
        </div>
      )}
    </div>
  );
}
