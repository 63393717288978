import { AboutUsCard } from "../components";
import About from "../images/About-us-page.gif";

interface AboutUsInterface {
  items: { title: string; description: string; subDescription?: string[] }[];
}

export function AboutUs({ items }: AboutUsInterface) {
  //Add code for about us section
  return (
    <div
      id="about"
      className="sm:flex sm:flex-col-reverse relative flex flex-col lg:flex-row justify-between lg:gap-6 p-6 bg-blue-200 min-h-screen"
    >
      <div className="w-full lg:w-1/2 flex flex-col justify-center items-start z-10 ">
        <h2 className="text-xl md:text-3xl text-center md:text-left font-bold text-blue-900 mb-2">About us </h2>
        <h3 className="text-lg md:text-xl text-left font-semibold mb-4">Know More About Us</h3>
        <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-3 2xl:grid-cols-3 gap-4">
          {items.map((item, index) => (
            <AboutUsCard key={index} title={item.title} description={item.description} subDescription={item.subDescription} />
          ))}
        </div>
      </div>

      <div className="w-full h-[15%] lg:w-1/2">
        <img src={About} alt="background" className="top-0 left-0 w-full h-[15%] lg:h-full object-contain" />
      </div>
    </div>
  );
}
