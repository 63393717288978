import { PopupButton } from "react-calendly";
import { PlayStoreButton } from "../components";
import { CALENDLY_URL, PLAYSTORE_URL } from "../constants";
import HeroImage from "../images/heroImage.png";

export function Landing() {
  return (
    <div id="home" className="lg:flex lg:flex-row md:items-center lg:justify-between bg-white text-center py-3 sm:flex sm:flex-col-reverse">
      <div className="md:w-1/2 md:pr-8 md:ml-10 md:pl-8 flex flex-col items-center justify-start  ">
        <div className="flex-1 text-center lg:text-left lg:pr-6 mb-6 lg:mb-0 flex flex-col justify-center ">
          <h1 className="text-3xl md:whitespace-nowrap font-bold text-gray-900 md:text-4xl lg:tracking-tight lg:text-4xl md:mb-4 font-poppins">
            Welcome to RentHub
          </h1>
          <p className="mt-4 text-lg text-gray-600 sm:max-w-3xl md:max-w-6xl lg:max-w-2xl text-centre font-poppins">
            Your all-in-one Property Management Solution. Simplify your property management with our comprehensive platform.
          </p>
        </div>
        <div className="sm:flex sm:flex-col lg:flex-row lg:gap-6 justify-center md:justify-center lg:justify-start items-center md:grid md:grid-cols-2 md:gap-x-12  lg:self-start">
          <PlayStoreButton url={PLAYSTORE_URL} />

          <PopupButton
            url={CALENDLY_URL}
            rootElement={document.getElementById("root")!}
            text="Request demo"
            className="py-4 px-4 text-white text-xl bg-blue-500 rounded-md hover:bg-blue-00 focus:outline-none focus:ring-2 focus:ring-blue-300 border-gray-500"
          />
        </div>
      </div>
      <div className="mt-10 w-full lg:mt-0 lg:w-1/2 lg:mb-5 flex justify-center lg:justify-end md:mt-0">
        <img src={HeroImage} alt="Hero" className="w-full h-auto object-cover" />
      </div>
    </div>
  );
}
