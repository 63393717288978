import { NavHashLink } from "react-router-hash-link";

export function NavigationLink() {
  return (
    <>
      <NavHashLink to="/#home" className="text-sm lg:text-lg font-medium hover:underline">
        Home
      </NavHashLink>
      <NavHashLink to="/#about" className="text-sm lg:text-lg font-medium hover:underline">
        About Us
      </NavHashLink>
      <NavHashLink to="/#features" className="text-sm lg:text-lg font-medium hover:underline">
        Features
      </NavHashLink>
      <NavHashLink to="/#testimonials" className="text-sm lg:text-lg font-medium hover:underline">
        Testimonials
      </NavHashLink>
      <NavHashLink to="/#faq" className="text-sm lg:text-lg font-medium hover:underline">
        FAQ
      </NavHashLink>
      <NavHashLink to="/#contact" className="text-sm lg:text-lg font-medium hover:underline">
        Contact Us
      </NavHashLink>
    </>
  );
}
