import { NavLink } from "react-router-dom";
import { Address, NavigationLink, PlayStoreButton, SocialIcons } from "../components";
import { PLAYSTORE_URL } from "../constants";
import "../index.css";

export function Footer() {
  return (
    <footer className="bg-primary py-2 px-5 mt-1">
      <div className="md:container md:mx-auto grid grid-cols-1 md:grid-cols-3 gap-x-20 ml-1 mr-1">
        <div className="flex flex-col col-start md:mr-8 ">
          <SocialIcons />
          <PlayStoreButton url={PLAYSTORE_URL} />
        </div>
        <div className="flex flex-col md:items-start  items-center  mb-5">
          <Address />
        </div>
        <div className="flex flex-col items-center mb-4 md:items-start ">
          <NavigationLink />
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center border-t border-gray-800 pt-2">
        <p className="mb-2 md:mb-0">© 2024 Rent Hub</p>
        <div className="flex gap-2">
          <NavLink to="policy" className={({ isActive }) => (isActive ? "hover:underline" : undefined)}>
            Privacy Policy
          </NavLink>
          <NavLink to="policy" className={({ isActive }) => (isActive ? "hover:underline" : undefined)}>
            Terms & condition
          </NavLink>
        </div>
      </div>
    </footer>
  );
}
