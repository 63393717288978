interface CardData {
  title: string;
  description: string;
  subDescription?: string[];
}

export const DUMMY_ABOUT_CARD_DATA: CardData[] = [
  {
    title: "Our story",
    description:
      "Founded in 2024, RentHub was created to address the common challenges faced by property managers and tenants. Our goal is to offer a seamless experience through innovative technology.",
  },
  {
    title: "Our mission",
    description: "To simplify property management by providing an all-in-one platform that meets the needs of both landlords and tenants.",
  },
  {
    title: "Customer satisfaction",
    description:
      "Our dedicated support team is always ready to assist you. Whether you have questions, need help with the app, or have any concerns, we are here to provide timely and effective solutions.",
  },
  {
    title: "Quality assuarance",
    description:
      "At RentHub, we are dedicated to providing the highest quality service to our users. Our commitment to excellence is reflected in every aspect of our platform.",
  },
  {
    title: "Core values",
    description: "Our core values include:",
    subDescription: ["Innovation", "Customer Focus", "Integrity", "Efficiency", "Reliability"],
  },
];
