import { useState } from "react";

interface FeaturesCardProps {
  icon: string;
  title: string;
  description: string;
  backgroundColor: string;
  hoverBackgroundColor: string;
  className?: string;
  style?: React.CSSProperties;
}

export function FeaturesCard(data: FeaturesCardProps) {
  const { icon, title, description, backgroundColor, hoverBackgroundColor, className, style = {} } = data;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={`transition-transform transition-shadow transition-colors duration-300 hover:scale-105 hover:shadow-lg p-4 rounded-lg shadow-md cursor-pointer ${className}`}
      style={{
        ...style,
        backgroundColor: isHovered ? hoverBackgroundColor : backgroundColor,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img src={icon} alt={title} className="w-12 h-12 mx-auto" />
      <h3 className="text-lg font-semibold text-center mt-2">{title}</h3>
      <p className="text-center mt-1">{description}</p>
    </div>
  );
}
