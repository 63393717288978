import { useState } from "react";
import { PopupButton } from "react-calendly";
import { FiMenu } from "react-icons/fi";
import { NavHashLink } from "react-router-hash-link";
import { Drawer } from "../components";
import { CALENDLY_URL, PLAYSTORE_URL } from "../constants";
import RentHubLogo from "../images/rent_hub-white_bg.png";
import "../index.css";

export function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <header className="sticky top-0 z-50 w-full bg-primary border-b">
      <div className=" w-full  flex items-center justify-between lg:flex-row ">
        <a href="/" className="flex items-center gap-2">
          <img src={RentHubLogo} alt="RentHub Logo" className="h-14 w-auto md:h-16" />
          <span className="sr-only">RentHub Logo</span>
        </a>
        <nav className="hidden lg:flex  items-center gap-6">
          <NavHashLink to="/#home" className="text-sm lg:text-lg font-medium hover:underline">
            Home
          </NavHashLink>
          <NavHashLink to="/#about" className="text-sm lg:text-lg font-medium hover:underline">
            About
          </NavHashLink>
          <NavHashLink to="/#features" className="text-sm lg:text-lg font-medium hover:underline">
            Features
          </NavHashLink>
          <NavHashLink to="/#testimonials" className="text-sm lg:text-lg font-medium hover:underline">
            Testimonials
          </NavHashLink>
          <NavHashLink to="/#faq" className="text-sm lg:text-lg font-medium hover:underline">
            FAQs
          </NavHashLink>
        </nav>
        <div className="hidden lg:inline-flex lg:flex items-center gap-2">
          <a href={PLAYSTORE_URL} target="_blank" rel="noopener noreferrer">
            <button className="py-2 px-4 bg-blue-700 text-white rounded-full hover:bg-blue-900">Download</button>
          </a>

          <PopupButton
            url={CALENDLY_URL}
            rootElement={document.getElementById("root")!}
            text="Request demo"
            className="py-2 px-4 bg-blue-100 rounded-full hover:bg-blue-00 focus:outline-none focus:ring-2 focus:ring-blue-300 border-gray-500"
          />
        </div>
        <button onClick={toggleDrawer} className="lg:hidden p-2 text-gray-700 hover:text-gray-900">
          <FiMenu className="text-3xl" />
          <span className="sr-only">Toggle Menu</span>
        </button>
        <Drawer isOpen={isOpen} toggleDrawer={toggleDrawer} />
      </div>
    </header>
  );
}
